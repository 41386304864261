import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@/styles/index.scss'
import 'amfe-flexible' // 
// import VueWechatTitle from 'vue-wechat-title'

// Vue.use(VueWechatTitle)
import VueAnalytics from 'vue-analytics'


Vue.use(VueAnalytics, {
	id: 'G-J3JMKSFB8N',
	router,
	autoTracking: {
		pageviewOnLoad: false
	}
})



router.beforeEach((to, from, next) => {
	// console.log(location.href);
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	next();
});
window.dataLayer = window.dataLayer || []
function gtag() {
	window.dataLayer.push(arguments)
}

// router.afterEach((to, from, next) => {
// 	gtag('set', 'page', to.path + 'L')
// 	gtag('consent', 'pageview')
// 	gtag('config', 'G-J3JMKSFB8N', { 'page_title': to.meta.title + 'L', 'page_path': to.fullPath + 'L' })
// })

import '@vant/touch-emulator';
import Vant from 'vant';
import 'vant/lib/index.css';
import touch from 'vue-directive-touch';
Vue.use(touch);

import VueTouch from "vue-touch";
Vue.use(VueTouch, { name: "v-touch" });
// 

//
import { getRandomAlphaNum } from '@/utils/config.js'
Vue.prototype.getRandomAlphaNum = getRandomAlphaNum
import { preventReClick } from '@/utils/directive.js'
Vue.directive('preventReClick', preventReClick)



// //
import Vconsole from 'vconsole';
let vconsole = new Vconsole;
vconsole

// Vue.prototype.$api = api
import '@/styles/index.scss'
// import '@/icons' // icon
Vue.use(Vant);
Vue.config.productionTip = false

// 
import * as filters from './filters' // global filters
// register global utility filters
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})



new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
