import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/sdk'
	},
	{
		path: '/sdk',
		name: 'sdk',
		component: () => import('@/views/sdk.vue'),
		meta: {
			title: 'sdk'
		}
	},
]

const router = new VueRouter({
	routes
})

// router.beforeEach(async (to, from, next) => {
// 	// console.log(from);
// 	if (from.path == '/bankcard') {
// 		let userList1 = JSON.parse(window.baseAndroid.logins())
// 		// console.log(217, userList1['313C']);
// 		// gtag('event', 'bankcard_loan', { 'send_to': 'G-J3JMKSFB8N', 'customer_id': userList1['313C'] });
// 	} else if (from.path == '/welcome') {
// 		// gtag('event', 'welcome_loan', { 'send_to': 'G-J3JMKSFB8N' });
// 	}
// 	if (to.meta.title) {
// 		document.title = to.meta.title
// 	}
// 	next()
// });

export default router
