import Vue from 'vue'
const preventReClick = Vue.directive('preventReClick', {
	inserted: function (el, binding, vNode, oldVnode) {
		el.addEventListener('click', () => {
			if (!el.disabled) {
				el.disabled = true
				el.style.backgroundColor = '#ccc'
				el.style.border = 'none'
				setTimeout(() => {
					el.disabled = false
					el.style.backgroundColor = '#000'
					el.style.border = '1px solid #000'
				}, 2000)
			}
		})
	}
})

export default {
	preventReClick
}
